import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <a href="https://anilgeorge.blog/building" target="_blank">
    <div>
        <h5>What else am I upto?</h5>
        <img src="otherlogos/ag_transparent.png" alt="Anil George" style={{
          "height": "200px",
          "width": "200px"
        }} />
    </div>
    </a>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      