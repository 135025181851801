import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`Welcome to the Cave!`}</h3>
    <p>{`Hi! I’m Anil, and I like to stay organized. I have experimented with a bunch of frameworks and tools to manage my personal and professional life.
The powerful features, interface, and flexibility of `}<a parentName="p" {...{
        "href": "https://www.notion.so/"
      }}>{`Notion`}</a>{` won me over.`}</p>
    <img src="notion/templates-illustration.png" alt="Notion Templates Illustration" style={{
      "width": "100%",
      "maxWidth": "1100px",
      "height": "auto"
    }} />
    <p>{`I’m regularly hacking away on Notion to build systems for `}</p>
    <ul>
      <li parentName="ul">{`my startup, ex: `}<inlineCode parentName="li">{`Wiki`}</inlineCode>{`, `}<inlineCode parentName="li">{`OKRs`}</inlineCode>{`, `}<inlineCode parentName="li">{`Project Management Tools`}</inlineCode></li>
      <li parentName="ul">{`my personal life, ex: `}<inlineCode parentName="li">{`Trackers`}</inlineCode>{`, `}<inlineCode parentName="li">{`LifeOS`}</inlineCode>{`, `}<inlineCode parentName="li">{`Relationship Manager`}</inlineCode></li>
    </ul>
    <p>{`At `}<strong parentName="p">{`Minimal Cave`}</strong>{`, you will find `}<a parentName="p" {...{
        "href": "/templates",
        "title": "build your systems with these templates"
      }}>{`templates`}</a>{` that I use often.
I like to keep things simple, actionable and minimal.
`}<a parentName="p" {...{
        "href": "https://twitter.com/anilgeorge04"
      }}>{`DM me on Twitter`}</a>{` if you find them helpful or need any assistance.`}</p>
    <h5>{`Get `}<a href="https://minimalcave.gumroad.com" target="_blank">{`instant access`}</a>{` to all templates`}</h5>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      